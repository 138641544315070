import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

import { firebaseConfig } from 'utils/configs';

let firebaseAnalytics = null;
let firebaseAuth = null;

const firebaseApp = initializeApp(firebaseConfig);

if (firebaseApp.name && typeof window !== 'undefined') {
  firebaseAnalytics = getAnalytics(firebaseApp);
  firebaseAuth = getAuth(firebaseApp);
}

export { firebaseApp, firebaseAuth, firebaseAnalytics };
