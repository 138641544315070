import React, { useState } from "react";
import Image from "next/image";
import { firebaseAuth } from "utils/firebaseConfig";
import { httpCall } from "functions/httpAction";

const CommentModal = (props) => {
  const { isShow, setIsShow, isCommon = false } = props;

  const [isSelected, setIsSelected] = useState(false)

  const updateShowState = async () => {
    const auth = firebaseAuth;
    const user = auth.currentUser;
    const token = user.accessToken;
    const passObject = JSON.stringify({ "show_review_text_tutorial": false });

    try {
      const [success, error, statusCode, data] = await httpCall('post', process.env.NEXT_PUBLIC_API_HOST + '/api/v2/me/updateData', token, passObject);
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  if (!isShow) return null;

  return (
    <>
      <div className="register-popupbg hidden lg:flex" onClick={() => setIsShow(false)}>
        <div className="register-popup" onClick={e => e.stopPropagation()}>
          <div className="flex flex-row justify-between items-center mt-[16px] mx-[16px] relative h-[48px] ">
            <div className="flex justify-center items-center ">
              <div
                className="border-[1px] border-[#E7E7E7] 
            w-[48px] h-[48px] rounded-[8px] flex items-center justify-center
            cursor-pointer absolute top-0 left-0 "
                onClick={() => setIsShow(false)}
              >
                <Image
                  src="/svgs/close.svg"
                  alt=""
                  width={30}
                  height={30}
                />
              </div>
            </div>
            <div></div>
            <div />
          </div>

          <div className="mx-[16px] my-[24px] flex-grow flex flex-col justify-between">
            <div>
              <div className="flex items-center justify-between">
                <div className="font-[700] text-[30px]">評價文字</div>
                {!isCommon && <div className='px-[8px] py-[4px] bg-[#F5F5F5] flex items-center rounded-md text-grey-800 text-[12px]'>
                  超過30字評價+&nbsp;
                  <div className=' bg-sorra-pink-light rounded-[50%] w-[16px] h-[16px] flex items-center'><img src='/svgs/sorra-logo-small.svg' className='p-[4px] ' /></div>
                  {' '}$5
                </div>}
              </div>

              <div className="mt-[10px] text-[12px] font-[400] text-[#9B9B9B]">
                我們鼓勵用戶留下任何有意義的評論，不論是正面還是負面的評論都能幫助其他用戶做出適當的選擇。唯獨垃圾留言，如無意義的文字或惡意洗版，將有機會被刪除。
              </div>

              <div className="mt-[28px] text-[20px] font-bold ">評價文字指示</div>

              <div className="mt-[10px] ml-4">
                <ul className="list-disc">
                  <li>內容須與產品用後感相關，並提供個人真實使用經驗</li>
                  <li><span className="font-[700]">不接受</span>含任何優惠、宣傳或任何涉及個人利益的廣告信息。</li>
                  <li>所有內容必須為原創，不得直接轉載品牌方，或以任何形式使用他人創作的內容</li>
                </ul>
              </div>

              <div className="mt-[28px] text-[20px] font-bold ">合獎勵資格之留言</div>

              <div className="mt-[10px] ml-4">
                <ul className="list-disc">
                  <li>評價必須包含至少30個字符</li>
                </ul>
              </div>
            </div>

            <div>

              {isCommon ? <div className="mt-[18px] h-[20px]"></div> : <div className="mt-[18px] flex items-center space-x-2">
                <input
                  className={`rounded-[4px] w-[20px] h-[20px] text-sorra-pink focus:ring-0 cursor-pointer`}
                  type="checkbox"
                  value={isSelected}
                  onChange={(value) => setIsSelected(value)}
                />
                <div className="text-[#111928]">下次不需要顯示</div>
              </div>}

              <div className="mt-[32px] flex items-center justify-center ">
                <button
                  className="bg-sorra-pink w-[343px] h-[48px] rounded-[8px] text-[#fff] flex items-center justify-center"
                  onClick={async () => {
                    if (isCommon) {
                      setIsShow(false)
                    } else {
                      if (isSelected) {
                        await updateShowState()
                      }

                      setIsShow(false)
                    }
                  }}
                >
                  {isCommon ? '關閉' : '開始評價'}
                </button>
              </div>

            </div>

            {/* <div className='mt-[32px] flex items-center p-[24px] border rounded-lg text-gray-800 cursor-pointer'>
              <img src='/svgs/upload.svg' width={20} height={20} alt='' className='mr-[8px] mt-[2px]' />
              上傳認證照
            </div> */}
          </div>
        </div>
      </div>

      <div className="block lg:hidden">
        <div className="register-popupbg">
          <div className="mt-[32px] h-[calc(100%-32px)] bg-white rounded-t-[16px] relative flex flex-col justify-between">
            <div
              className="pt-[16px] pl-[16px]"
              onClick={() => setIsShow(false)}
            >
              <Image
                src="/svgs/close.svg"
                alt=""
                width={20}
                height={20}
              />
            </div>

            <div className="flex flex-col h-[calc(100%-36px)] py-[24px] px-[16px] overflow-scroll">

              <div>
                <div className="flex items-center justify-between">
                  <div className="font-[700] text-[30px]">評價文字</div>
                  {!isCommon && <div className='px-[8px] py-[4px] bg-[#F5F5F5] flex items-center rounded-md text-sorra-grey-800 text-[12px]'>
                    超過30字評價+&nbsp;
                    <div className=' bg-sorra-pink-light rounded-[50%] w-[16px] h-[16px] flex items-center'><img src='/svgs/sorra-logo-small.svg' className='p-[4px] ' /></div>
                    {' '}$5
                  </div>}
                </div>
                <div className="mt-[10px] text-[12px] font-[400] text-[#9B9B9B]">
                  我們鼓勵用戶留下任何有意義的評論，不論是正面還是負面的評論都能幫助其他用戶做出適當的選擇。唯獨垃圾留言，如無意義的文字或惡意洗版，將有機會被刪除。
                </div>

                <div className="mt-[28px] text-[20px] font-bold ">評價文字指示</div>

                <div className="mt-[10px] ml-4">
                  <ul className="list-disc">
                    <li>內容須與產品用後感相關，並提供個人真實使用經驗</li>
                    <li><span className="font-[700]">不接受</span>含任何優惠、宣傳或任何涉及個人利益的廣告信息。</li>
                    <li>所有內容必須為原創，不得直接轉載品牌方，或以任何形式使用他人創作的內容</li>
                  </ul>
                </div>

                <div className="mt-[28px] text-[20px] font-bold ">合獎勵資格之留言</div>

                <div className="mt-[10px] ml-4">
                  <ul className="list-disc">
                    <li>評價必須包含至少30個字符</li>
                  </ul>
                </div>

                {isCommon ? <div className="mt-[30px] h-[20px]"></div> : <div className="mt-[30px] flex items-center space-x-2">
                  <input
                    className={`rounded-[4px] w-[20px] h-[20px] text-sorra-pink focus:ring-0 cursor-pointer`}
                    type="checkbox"
                    value={isSelected}
                    onChange={(value) => setIsSelected(value)}
                  />
                  <div className="text-[#111928]">下次不需要顯示</div>
                </div>}
              </div>

            </div>

            <div className="flex items-center justify-center p-4">
              <button
                className="bg-sorra-pink w-full h-[48px] rounded-[8px] text-[#fff] flex items-center justify-center"
                onClick={async () => {
                  if (isCommon) {
                    setIsShow(false)
                  } else {
                    if (isSelected) {
                      await updateShowState()
                    }

                    setIsShow(false)
                  }
                }}
              >
                {isCommon ? '關閉' : '開始評價'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommentModal;