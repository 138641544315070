import React, { useState } from "react";
import Image from "next/image";
import { firebaseAuth } from "utils/firebaseConfig";
import { httpCall } from "functions/httpAction";

const UserAuthModal = (props) => {
  const { isShow, setIsShow, authImageRef, isCommon = false } = props;

  const [isSelected, setIsSelected] = useState(false)

  const updateShowState = async () => {
    const auth = firebaseAuth;
    const user = auth.currentUser;
    const token = user.accessToken;
    const passObject = JSON.stringify({ "show_review_cert_tutorial": false });

    try {
      const [success, error, statusCode, data] = await httpCall('post', process.env.NEXT_PUBLIC_API_HOST + '/api/v2/me/updateData', token, passObject);
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  if (!isShow) return null;

  return (
    <>
      <div className="register-popupbg hidden lg:flex" onClick={() => setIsShow(false)}>
        <div className="register-popup" onClick={e => e.stopPropagation()}>
          <div className="flex flex-row justify-between items-center mt-[16px] mx-[16px] relative h-[48px] ">
            <div className="flex justify-center items-center ">
              <div
                className="border-[1px] border-[#E7E7E7] 
            w-[48px] h-[48px] rounded-[8px] flex items-center justify-center
            cursor-pointer absolute top-0 left-0 "
                onClick={() => setIsShow(false)}
              >
                <Image
                  src="/svgs/close.svg"
                  alt=""
                  width={30}
                  height={30}
                />
              </div>
            </div>
            <div></div>
            <div />
          </div>

          <div className="mx-[16px] my-[24px]">
            <div className="flex items-center justify-between">
              <div className="font-[700] text-[30px]">真實用家認證</div>
              {!isCommon && <div className='px-[8px] py-[4px] bg-[#F5F5F5] flex items-center rounded-md text-sorra-grey-800 text-[12px]'>
                上傳後額外+&nbsp;
                <div className=' bg-sorra-pink-light rounded-[50%] w-[16px] h-[16px] flex items-center'><img src='/svgs/sorra-logo-small.svg' className='p-[4px] ' /></div>
                {' '}$10
              </div>}
            </div>

            <div className="mt-[10px] text-[12px] font-[500] text-[#9B9B9B]">
              認證照片是我們驗證<span className="font-bold">用戶真實擁有產品的重要依據</span>，有助防止濫發評論和提供更準確的產品評價，提高Sorra的可信度和資訊的可靠性。
            </div>

            <div className="mt-[28px] text-[20px] font-bold ">照片標準</div>

            <div className="mt-[10px] ml-4">
              <ul className="list-disc">
                <li>請拍攝產品<span className="font-bold">背面</span>或<span className="font-bold">底部</span>，或購買憑證</li>
                <li>所有圖片必須為原創，嚴禁使用網上截圖，或以任何形式使用他人創作的內容</li>
              </ul>
            </div>

            <div className="mt-[14px] flex items-center gap-x-3 overflow-scroll">
              <div className="relative">
                <div className="absolute text-[20px] top-1 left-3">✅</div>
                <img src="/images/realUser/realUser-1.png" alt="" />
              </div>
              <div className="relative">
                <div className="absolute text-[20px] top-1 left-3">✅</div>
                <img src="/images/realUser/realUser-2.png" alt="" />
              </div>
              <div className="relative">
                <div className="absolute text-[20px] top-1 left-3">✅</div>
                <img src="/images/realUser/realUser-3.png" alt="" />
              </div>
            </div>

            {isCommon ? <div className="mt-[18px] h-[20px]"></div> : <div className="mt-[18px] flex items-center space-x-2">
              <input
                className={`rounded-[4px] w-[20px] h-[20px] text-sorra-pink focus:ring-0 cursor-pointer`}
                type="checkbox"
                value={isSelected}
                onChange={(value) => setIsSelected(value)}
              />
              <div className="text-[#111928]">下次不需要顯示</div>
            </div>}

            <div className="mt-[32px] flex items-center justify-center ">
              <button
                className="bg-sorra-pink w-[343px] h-[48px] rounded-[8px] text-[#fff] flex items-center justify-center"
                onClick={async () => {
                  if (isCommon) {
                    setIsShow(false)
                  } else {
                    if (isSelected) {
                      await updateShowState()
                    }

                    authImageRef.current.click();
                    setIsShow(false)
                  }
                }}
              >
                {!isCommon && <img src='/svgs/upload_white.svg' width={20} height={20} alt='' className='mr-[8px] mt-[2px]' />}
                {!isCommon ? '上傳認證照' : '關閉'}
              </button>
            </div>

            {/* <div className='mt-[32px] flex items-center p-[24px] border rounded-lg text-gray-800 cursor-pointer'>
              <img src='/svgs/upload.svg' width={20} height={20} alt='' className='mr-[8px] mt-[2px]' />
              上傳認證照
            </div> */}
          </div>
        </div>
      </div>

      <div className="block lg:hidden">
        <div className="register-popupbg">
          <div className="mt-[32px] h-[calc(100%-32px)] bg-white rounded-t-[16px] relative flex flex-col justify-between">
            <div
              className="pt-[16px] pl-[16px]"
              onClick={() => setIsShow(false)}
            >
              <Image
                src="/svgs/close.svg"
                alt=""
                width={20}
                height={20}
              />
            </div>

            <div className="flex flex-col h-[calc(100%-36px)] py-[24px] px-[16px] overflow-scroll">

              <div>
                <div className="flex items-center justify-between">
                  <div className="font-[700] text-[30px]">真實用家認證</div>
                  {!isCommon && <div className='px-[8px] py-[4px] bg-[#F5F5F5] flex items-center rounded-md text-sorra-grey-800 text-[12px]'>
                    上傳後額外+&nbsp;
                    <div className=' bg-sorra-pink-light rounded-[50%] w-[16px] h-[16px] flex items-center'><img src='/svgs/sorra-logo-small.svg' className='p-[4px] ' /></div>
                    {' '}$10
                  </div>}
                </div>
                <div className="mt-[10px] text-[12px] font-[500] text-[#9B9B9B]">
                  認證照片是我們驗證<span className="font-bold">用戶真實擁有產品的重要依據</span>，有助防止濫發評論和提供更準確的產品評價，提高Sorra的可信度和資訊的可靠性。
                </div>

                <div className="mt-[28px] text-[20px] font-bold ">照片標準</div>

                <div className="mt-[10px] ml-4">
                  <ul className="list-disc">
                    <li>請拍攝產品<span className="font-bold">背面</span>或<span className="font-bold">底部</span>，或購買憑證</li>
                    <li>所有圖片必須為原創，嚴禁使用網上截圖，或以任何形式使用他人創作的內容</li>
                  </ul>
                </div>

                <div className="mt-[14px] flex items-center justify-between h-[153px] space-x-2">
                  <div className="flex-1 rounded relative">
                    <div className="absolute text-[20px] top-1 left-3">✅</div>
                    <img src="/images/realUser/realUser-1.png" alt="" className="object-cover w-full h-[153px] rounded" />
                  </div>
                  <div className="flex-1 rounded relative">
                    <div className="absolute text-[20px] top-1 left-3">✅</div>
                    <img src="/images/realUser/realUser-2.png" alt="" className="object-cover w-full h-[153px] rounded" />
                  </div>
                </div>

                <div className="w-full h-[182px] mt-2 rounded relative">
                  <div className="absolute text-[20px] top-1 left-3">✅</div>
                  <img src="/images/realUser/realUser-3.png" alt="" className="object-cover w-full h-full rounded" />
                </div>

                {isCommon ? <div className="mt-[30px] h-[20px]"></div> : <div className="mt-[30px] flex items-center space-x-2">
                  <input
                    className={`rounded-[4px] w-[20px] h-[20px] text-sorra-pink focus:ring-0 cursor-pointer`}
                    type="checkbox"
                    value={isSelected}
                    onChange={(value) => setIsSelected(value)}
                  />
                  <div className="text-[#111928]">下次不需要顯示</div>
                </div>
                }
              </div>

            </div>

            <div className="flex items-center justify-center p-4">
              <button
                className="bg-sorra-pink w-full h-[48px] rounded-[8px] text-[#fff] flex items-center justify-center"
                onClick={async () => {
                  if (isCommon) {
                    setIsShow(false)
                  } else {
                    if (isSelected) {
                      await updateShowState()
                    }

                    authImageRef.current.click();
                    setIsShow(false)
                  }
                }}
              >
                {!isCommon && <img src='/svgs/upload_white.svg' width={20} height={20} alt='' className='mr-[8px] mt-[2px]' />}
                {!isCommon ? '上傳認證照' : '關閉'}
              </button>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAuthModal;