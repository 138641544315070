import React from 'react';
import Image from 'next/image';

import MaxWidthContainer from 'components/MaxWidthContainer';

const Footer = (props) => {
    const { ...restProps } = props;

    return (
        <>
            <footer className="footer-frame">
                <MaxWidthContainer className="flex-wrap h-full" >
                    <div className='block lg:grid grid-cols-1 lg:grid-cols-6 py-12 footer-inner'>
                        <div className='grid grid-flow-row auto-rows-max col-span-2 gap-y-4 footer-column'>
                            <img src='/images/sorra.png' className='footer-logo'></img>
                            <div className='grid grid-flow-col auto-cols-max gap-x-5'>
                                <img src='/svgs/footer-sns-instagram.svg' className='cursor-pointer' onClick={() => window.open('https://www.instagram.com/sorra.beauty', '_blank')} />
                                <img src='/svgs/footer-sns-facebook.svg' className='cursor-pointer' onClick={() => window.open('https://www.facebook.com/sorranet', '_blank')} />
                                {/* <img src='/svgs/footer-sns-youtube.svg'></img> */}
                            </div>
                        </div>
                        <div className='grid grid-flow-row auto-rows-max gap-y-5 footer-column'>
                            <span className='text-sm font-semibold footer-links-header'>
                                關於我們
                            </span>
                            <a href='/about' className='text-base font-normal text-sorra-gray-800'>
                                認識SORRA
                            </a>
                            <a href='/membership' className='text-base font-normal text-sorra-gray-800'>
                                會員制度
                            </a>
                            <a href='https://blog.sorra.net/' target='_blank' className='text-base font-normal text-sorra-gray-800'>
                                社群文章
                            </a>
                            <a href='mailto:info@sorra.net' className='text-base font-normal text-sorra-gray-800'>
                                聯絡我們
                            </a>
                        </div>
                        <div className='grid grid-flow-row auto-rows-max gap-y-5 footer-column'>
                            <span className='text-sm font-semibold footer-links-header'>
                                資訊
                            </span>
                            <a href='/ranking' className='text-base font-normal text-sorra-gray-800'>
                                全部排行榜
                            </a>
                            <a href='/rewards?tab=code' className='text-base font-normal text-sorra-gray-800'>
                                優惠碼合集
                            </a>
                            <a href='https://mart.sorra.net' target='_blank' className='text-base font-normal text-sorra-gray-800'>
                                SORRA MART
                            </a>
                            <a href='mailto:cs@sorra.net' className='text-base font-normal text-sorra-gray-800'>
                                幫助
                            </a>
                        </div>
                        <div className='grid grid-flow-row auto-rows-max gap-y-5 footer-column'>
                            <span className='text-sm font-semibold footer-links-header'>
                                探索
                            </span>
                            <a href='mailto:sales@sorra.net' className='text-base font-normal text-sorra-gray-800'>
                                商業或媒體聯絡
                            </a>
                            <a href='/nominate' className='text-base font-normal text-sorra-gray-800'>
                                新增產品
                            </a>
                        </div>
                        <div className='grid grid-flow-row auto-rows-max gap-y-5 footer-column'>
                            <span className='text-sm font-semibold footer-links-header'>
                                私隱與條款
                            </span>
                            <a href='https://sorra.notion.site/Private-Policy-sorra-net-14-Aug-2023-c8b664a4d40a42f0b35af9e4f416357a?pvs=4' target='_blank' className='text-base font-normal text-sorra-gray-800'>
                                私隱政策
                            </a>
                            <a href='https://sorra.notion.site/Website-Terms-and-Conditions-sorra-net-14-Aug-2023-e99d70976b7842c38495ee97a6074f13?pvs=4' target='_blank' className='text-base font-normal text-sorra-gray-800'>
                                條款及細則
                            </a>
                        </div>
                    </div>
                    <div className='flex py-8 lg:py-12 border-t border-gray-200 items-center justify-center text-base text-gray-400 footer-bottom'>
                        Copyright © 2023 Sorra. All rights reserved.
                    </div>
                </MaxWidthContainer>
            </footer>
        </>
    );
};

export default Footer;